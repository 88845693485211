import React from "react";
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";

function CourseCard({ courseName, 
                      courseDesc,
                      courseStartDate,
                      courseEndDate,
                      courseDurationInHours, 
                      courseDifficulty,
                      courseNumberOfLabs,
                      courseButtonText,
                      courseUrl}) {

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    function isValidUrl(string) {
        try {
            new URL(string);
            return true;
        } catch (err) {
            return false;
        }
    }    

    const difficultyColor = courseDifficulty === "Beginner" ? "success" : 
                courseDifficulty === "Intermediate" ? "warning" : "danger";

    return (
        <div className="panel panel-primary">
            <div className="panel-heading">{courseName}</div>
            <div className="panel-body">
                {courseDesc}
            </div>
            <div className="panel-body">
                {courseStartDate} - {courseEndDate}
            </div>
            <div className="panel-body">
                <span className="label label-primary">
                    <span className="glyphicon glyphicon-time" aria-hidden="true" />
                    <span> </span> {courseDurationInHours} Hours
                </span>
                <span> </span>
                <span className={`label label-${difficultyColor}`}>
                    <span className="glyphicon glyphicon-stats" aria-hidden="true" />
                    <span> </span> {courseDifficulty}
                </span>
                <span> </span>
                {courseNumberOfLabs && 
                <>
                    <span className="label label-default">
                        <span className="glyphicon glyphicon-glass" aria-hidden="true" />
                        <span> </span> {courseNumberOfLabs} Labs
                    </span>
                </>}
            </div>
            <div className="panel-footer">
                {isValidUrl(courseUrl) ? 
                <>
                    <button 
                        className="btn text-white btn-theme-green btn-block"
                        onClick={() => {
                                if (courseUrl)
                                    openInNewTab(courseUrl);
                            }}>
                            {courseButtonText}
                    </button>
                </> :
                <>
                    <Link
                        className="btn text-white btn-theme-green btn-block"
                        to={courseUrl}>
                        <span>{courseButtonText}</span>
                    </Link>
                </>}
            </div>
        </div>
    )
}

function SudaneseLearningHub() {

    const {t} = useTranslation();

    return (
        <div className="main-content">
            <section
                className="volunteer-bg-img inner-header divider parallax layer-overlay overlay-dark-6">
                <div className="container pt-60 pb-60">
                    <div className="section-content">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h3 className="font-28 text-white">{t("sudanese_learning_hub")}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-md-offset-1 tab-content">
                            <h4 className="text-gray pt-10 mt-0 mb-30">
                                In response to the recent conflict in Sudan, numerous Sudanese individuals, particularly the youth, have been displaced, leading to interruptions in their education and careers. Many are seeking avenues to acquire new skills or pursue different career paths.
                                <br /><br />To address this, Sadagaat is proud to collaborate with Cisco Networking Academy in launching the Sudanese Learning Hub. This initiative aims to provide top-tier practical education from Cisco, a global technology leader powering the Internet.
                                The courses offered are strategically designed to equip learners with the skills necessary for success in various job roles. 
                                Cisco Networking Academy has a longstanding reputation for its IT skills-to-jobs programs. Through robust public-private partnerships and high-quality curriculum, they've been instrumental in inclusive workforce development since 1997.
                                <br /><br />The Sudanese Learning Hub is committed to providing a range of Cisco Network Academy courses focusing on high-demand IT subjects like Cybersecurity, Networking, Data Science, Programming, and Operating Systems. These courses aim to pave the way for employment opportunities in the IT sector.
                                The Learning Hub will offer three types of courses under the 'Skills for All' platform, all of which are free of charge.
                            </h4>
                            <ul>
                                <div>
                                    <h2>1- Self-Paced</h2>
                                    <p>Access to beginner and intermediate courses from skillsforall.com.</p>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <CourseCard 
                                                courseName={"SP1-Introduction to Cyber Security"}
                                                courseDesc={"Explore the exciting field of cybersecurity and why cybersecurity is a future-proof career."}
                                                courseStartDate="Feb 1st, 2024"
                                                courseEndDate="Mar 15th, 2024"
                                                courseDurationInHours={6}
                                                courseDifficulty={"Beginner"}
                                                courseNumberOfLabs={7}
                                                courseButtonText={"Get Started"}
                                                courseUrl={"https://skillsforall.com/course/introduction-to-cybersecurity?courseLang=en-US&instance_id=98671dc1-4365-4733-aad2-bf7911518f3a"} />
                                        </div>
                                        <div className="col-md-3">
                                            <CourseCard 
                                                courseName={"SP1-Networking Basics"}
                                                courseDesc={"Start learning the basics of computer networking and discover how networks operate."}
                                                courseStartDate="Feb 1st, 2024"
                                                courseEndDate="Mar 15th, 2024"
                                                courseDurationInHours={22}
                                                courseDifficulty={"Beginner"}
                                                courseNumberOfLabs={13}
                                                courseButtonText={"Get Started"}
                                                courseUrl={"https://skillsforall.com/course/networking-basics?courseLang=en-US&instance_id=6c783516-fd3c-4e51-95ca-6cab6dc2c442"} />
                                        </div>
                                        <div className="col-md-3">
                                            <CourseCard 
                                                courseName={"SP1-Introduction to Data Science"}
                                                courseDesc={"Learn the basics of data science, data analytics, and data engineering to understand how machine learning is shaping the future of business, healthcare, education, and more."}
                                                courseStartDate="Feb 1st, 2024"
                                                courseEndDate="Mar 15th, 2024"
                                                courseDurationInHours={6}
                                                courseDifficulty={"Beginner"}
                                                courseNumberOfLabs={5}
                                                courseButtonText={"Get Started"}
                                                courseUrl={"https://skillsforall.com/course/introduction-data-science?courseLang=en-US&instance_id=ebc6adf2-e882-478b-bed0-0d3cfb551ff0"} />
                                        </div>
                                        <div className="col-md-3">
                                            <CourseCard 
                                                courseName={"SP1-Engaging Stakeholders for Success"}
                                                courseDesc={"Gain the insights and tools to identify, prioritize, and engage with stakeholders for success."}
                                                courseStartDate="Feb 1st, 2024"
                                                courseEndDate="Mar 15th, 2024"
                                                courseDurationInHours={3}
                                                courseDifficulty={"Beginner"}
                                                courseButtonText={"Get Started"}
                                                courseUrl={"https://skillsforall.com/course/engaging-stakeholders?courseLang=en-US&instance_id=2bbd406f-b33b-4d54-8f2e-06837dcf3d08"} />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h2>2- Self-Paced with tutor Support</h2>
                                    <p>These courses provide the flexibility of self-paced learning with the added benefit of an experienced tutor to support you whenever needed. 
                                        Starting Monday, Jan 22nd we will be offering the following three courses with experienced tutors support. 
                                        If you are interested in any of these or future courses, please register by clicking on the register button to complete this form, and we will reach out to you shortly.</p>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <CourseCard 
                                                courseName={"ST1-Introduction to Cyber Security"}
                                                courseDesc={"Explore the exciting field of cybersecurity and why cybersecurity is a future-proof career."}
                                                courseStartDate="Feb 7th, 2024"
                                                courseEndDate="Mar 2nd, 2024"
                                                courseDurationInHours={6}
                                                courseDifficulty={"Beginner"}
                                                courseNumberOfLabs={7}
                                                courseButtonText={"Register"}
                                                courseUrl={"/course-form"} />
                                        </div>
                                        <div className="col-md-4">
                                            <CourseCard 
                                                courseName={"ST1-Networking Basics"}
                                                courseDesc={"Start learning the basics of computer networking and discover how networks operate."}
                                                courseStartDate="Feb 7th, 2024"
                                                courseEndDate="Mar 14th, 2024"
                                                courseDurationInHours={22}
                                                courseDifficulty={"Beginner"}
                                                courseNumberOfLabs={13}
                                                courseButtonText={"Register"}
                                                courseUrl={"/course-form"} />
                                        </div>
                                        <div className="col-md-4">
                                            <CourseCard 
                                                courseName={"ST1-Introduction to Data Science"}
                                                courseDesc={"Learn the basics of data science, data analytics, and data engineering to understand how machine learning is shaping the future of business, healthcare, education, and more."}
                                                courseStartDate="Feb 12th, 2024"
                                                courseEndDate="Mar 1st, 2024"
                                                courseDurationInHours={6}
                                                courseDifficulty={"Beginner"}
                                                courseNumberOfLabs={5}
                                                courseButtonText={"Register"}
                                                courseUrl={"/course-form"} />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h2>3- Instructor-Led Courses</h2>
                                    <p>Certified instructors will conduct these courses for students seeking a more guided learning experience. 
                                        Starting Feb 5th, the Sudanese Learning Hub will commence its two instructor-led courses. 
                                        If you are interested in any of these or future courses, please register by clicking on the register button to complete this form, and we will reach out to you shortly.</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <CourseCard 
                                                courseName={"IL1-Cybersecurity Essentials"}
                                                courseDesc={"Preparing individuals for the entry-level Cisco Certified Support Technician (CCST) Cybersecurity certification and entry-level cybersecurity positions such as Cybersecurity Technician, Cybersecurity Analyst, or Tier 1 Help Desk Support roles. You will learn vital skills like threat intelligence, network security, and risk management to protect yourself and organizations from cyber attacks. If you enjoy solving puzzles, are passionate about technology, and like working in teams, the field of cybersecurity may be a great fit for you!"}
                                                courseStartDate="Feb 5th, 2024"
                                                courseEndDate="Mar 15th, 2024"
                                                courseDurationInHours={70}
                                                courseDifficulty={"Intermediate"}
                                                courseButtonText={"Register"}
                                                courseUrl={"/course-form"} />
                                        </div>
                                        <div className="col-md-6">
                                            <CourseCard 
                                                courseName={"IL1-Networking Basics"}
                                                courseDesc={"Preparing individuals for the entry-level Cisco Certified Support Technician (CCST) Network certification and entry-level roles such as Network Support Technician, Entry-level Help Desk Technician, or IT Support Specialist.  You will learn how networks operate, including the devices, media, and protocols that enable network communication. Develop key skills so you can perform basic troubleshooting, using effective methodologies and help desk best practices."}
                                                courseStartDate="Feb 5th, 2024"
                                                courseEndDate="Mar 15th, 2024"
                                                courseDurationInHours={65}
                                                courseDifficulty={"Intermediate"}
                                                courseButtonText={"Register"}
                                                courseUrl={"/course-form"} />
                                        </div>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SudaneseLearningHub;