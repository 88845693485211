import React, { useEffect, useState } from "react";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {animateScroll as scroll} from "react-scroll";
import { submit_course_registration } from "../../repository";

const REST_COUNTRIES_URL = "https://restcountries.com/v3.1/all?fields=name";
const REST_NATIONALITY_URL = "https://restcountries.com/v3.1/demonym/{0}?";
// const REST_CITIES_URL = "https://countriesnow.space/api/v0.1/countries/cities";

const formatString = (template, ...args) => {
    return template.replace(/{([0-9]+)}/g, function (match, index) {
        return typeof args[index] === 'undefined' ? match : args[index];
    });
}

function CourseForm() {
    const interestAreas = [
        "Networking",
        "Cyber Security",
        "Data Science",
        "Programming",
        "Operating Systems & IT"
    ];

    const whatTypeOfCoursesDoYouWantToRegister = [
        "Self-Paced with Tutor support - Introduction to Cyber Security",
        "Self-Paced with Tutor Support - Networking Basics",
        "Self-Paced with Tutor Support - Introduction to Data Science",
        "Instructor-Led - Cyber Security Essentials",
        "Instructor-Led - Networking Basics"
    ];

    const iTBackgrounds = [
        "Working as an IT professional",
        "Limited experience or no experience",
        "Fresh Graduate",
        "Currently a university student",
        "Other"
    ];

    const whyAreYouInterested = [
        "To get a new job",
        "To get promoted in my current job",
        "To enhance my technical skills in the IT field",
        "To expand my knowledge in a specific IT specialization",
        "To meet like-minded professionals and network in the IT field",
        "Other"
    ]

    const {t} = useTranslation();

    const [countries, setCountries] = useState([]);
    // const [cities, setCities] = useState([]);
    const [nationalities, setNationalities] = useState([]);

    const [response, setResponse] = useState({ styleClass: "", message: "" });

    // form fields
    const [name, setName] = useState("");
    const [gender, setGender] = useState(false); // False = Male, True = Female
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [nationality, setNationality] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [interestArea, setInterestArea] = useState(interestAreas[0]);
    const [iTBackground, setITBackground] = useState(iTBackgrounds[0]);
    const [iTBackgroundOthersText, setITBackgroundOthersText] = useState("");
    const [trainingType, setTrainingType] = useState(whatTypeOfCoursesDoYouWantToRegister[0]);
    const [haveLaptop, setHaveLaptop] = useState(true);
    const [haveGoodInternetAccess, setHaveGoodInternetAccess] = useState(true);
    const [courseInterestReason, setCourseInterestReason] = useState(whyAreYouInterested[0]);
    const [courseInterestReasonOthersText, setCourseInterestReasonOthersText] = useState("");
    const [comment, setComment] = useState("");

    // const citySelectRef = useRef();

    const handleFormErrorMessage = (e, message = "") => {
        if (e.target.value === "") e.target.setCustomValidity(t("fill this field"));
        else e.target.setCustomValidity(message);
    };

    useEffect(() => {
        fetch(REST_COUNTRIES_URL)
            .then(res => res.json())
            .then(data => {
                const list = data.map(d => d.name.common);
                list.sort();
                setCountries(list);
                handleCountryChanged(list[0]);

                // Fetch each country's nationalities
                const listOfPromises = list.map(c => {
                    return fetch(formatString(REST_NATIONALITY_URL + new URLSearchParams({
                        fields: "demonyms"
                    }), c.replace(/ /g, '%20')))
                        .then(res => res.json())
                        .catch(console.error)
                })

                Promise.all(listOfPromises)
                    .then(res => {
                        let nas = []
                        res.forEach(data => {
                            if (!Array.isArray(data))
                                return;

                            const n = data?.map(i => i.demonyms.eng.f);
                            nas = nas.concat(n);
                        })
                        nas.sort();
                        setNationalities(nas);
                        if (nas.length > 0)
                            setNationality(nas[0]);
                    })
            })
            .catch(console.error)
    }, []);

    async function handleCountryChanged(newCountry) {
        setCountry(newCountry);
        // setCities([]);
        setCity("");

        // if (!citySelectRef.current)
        //     return;

        // citySelectRef.current.disabled = true;
        // const res = await fetch(REST_CITIES_URL, {
        //     method: 'POST',
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify({
        //         country: newCountry
        //     })
        // });
        // const data = await res.json();
        // data.data.sort();
        // setCities(data.data);
        // setCity(data.data[0]);
        // citySelectRef.current.disabled = false;
    }

    function handleSubmit(e) {
        e.preventDefault();
        const data = {
            name,
            phoneNumber,
            gender: gender ? "FEMALE" : "MALE",
            email,
            dateOfBirth,
            nationality,
            location: `${country}#@@#${city}`, // Combing country and city into a single variable
            interestArea,
            iTBackground: iTBackgroundOthersText.length > 0 ? 
                iTBackgroundOthersText : iTBackground,
            trainingType,
            hasDevice: haveLaptop,
            hasInternet: haveGoodInternetAccess,
            reasoning: courseInterestReasonOthersText.length > 0 ? 
                courseInterestReasonOthersText : courseInterestReason,
            comment
        }

        submit_course_registration(data)
            .then(response => {
                setResponse({
                    message: "Your Form Submitted Successfully",
                    styleClass: "success-msg",
                })
            })
            .catch(err => {
                console.log(err);
                let message = err.message;
                if (err.message.includes("400"))
                    message = "It appears there may be a mistake or incorrect data entry; please review and resubmit."
                else if (err.message !== "Network Error")
                    message = "Something went wrong please try again";

                setResponse({
                    message: message,
                    styleClass: "error-msg",
                });
            });
            scroll.scrollTo(50);
    }

    return (
        <div className="main-content">
            <section
                className="volunteer-bg-img inner-header divider parallax layer-overlay overlay-dark-6">
                <div className="container pt-60 pb-60">
                    <div className="section-content">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h3 className="font-28 text-white">Course Form</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-md-offset-1">
                            <div className="tab-content">
                                <div className="tab-pane active p-15" id="register-tab">
                                    <form
                                        id="course-reg-form"
                                        name="course-form"
                                        className="register-form"
                                        onSubmit={handleSubmit}>
                                            <div className="icon-box mb-0 p-0">
                                                <h4 className="text-gray pt-10 mt-0 mb-30">
                                                    {t("form_intro")}
                                                </h4>
                                            </div>

                                            <div 
                                                className={`${response.styleClass} bold`} 
                                                role="alert">
                                                <p> {t(response.message)} </p>
                                            </div>

                                            <h4 className="text-gray mt-20">
                                                {"- " + t("candidate_information")}
                                            </h4>

                                            <div className="row">
                                                <div className="form-group required col-md-12">
                                                    <label className="control-label">Name</label>
                                                    <input
                                                        name="name"
                                                        className="form-control"
                                                        type="text"
                                                        onChange={(e) => {setName(e.target.value)}}
                                                        pattern="^([A-Za-z\u0621-\u064A]+)\s([A-Za-z\u0621-\u064A]+)(\s[A-Za-z\u0621-\u064A]+)?(\s[A-Za-z\u0621-\u064A]+)?([A-Za-z\u0621-\u064A\s]+)?$"
                                                        title={t("Please enter your fullName")}
                                                        value={name}
                                                        required="required"
                                                        onInvalid={(e) =>
                                                            handleFormErrorMessage(e, 
                                                                                "Please enter your fullName")
                                                        }
                                                        onInput={function (e) {
                                                            e.target.setCustomValidity(t(""));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group required col-md-12">
                                                    <label htmlFor="" className="control-label">
                                                        {"E-Mail"}
                                                    </label>
                                                    <input
                                                        id=""
                                                        name="email"
                                                        className="form-control"
                                                        type="text"
                                                        onChange={(e) => {setEmail(e.target.value)}}
                                                        pattern="^([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-z]{2,8})(\.[a-z]{2,8})?$"
                                                        title={t("that email address is invalid")}
                                                        value={email}
                                                        required="required"
                                                        onInvalid={(e) =>
                                                            handleFormErrorMessage(e,
                                                                                "that email address is invalid")
                                                        }
                                                        onInput={function (e) {
                                                            e.target.setCustomValidity(t(""));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group required col-md-6">
                                                    <label htmlFor="" className="control-label">
                                                        {"Phone"}
                                                    </label>
                                                    <small className="font-12 text-gray"></small>
                                                    <input
                                                        name="phoneNumber"
                                                        className="form-control"
                                                        type="tel"
                                                        onChange={(e) => {setPhoneNumber(e.target.value)}}
                                                        pattern="^\+?(\d{1,3})?\s?\d{4,13}$"
                                                        title={(
                                                            "Enter a valid phone number ranges from 4 to 13 digits"
                                                        )}
                                                        value={phoneNumber}
                                                        required="required"
                                                        onInvalid={(e) =>
                                                            handleFormErrorMessage(e, "Enter a valid phone number ranges from 4 to 13 digits")
                                                        }
                                                        onInput={function (e) {
                                                            e.target.setCustomValidity(t(""));
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-group required col-md-6">
                                                    <label className="control-label" htmlFor="">{"Date Of Birth"}</label>
                                                    <input
                                                        id="date"
                                                        name="dateOfBirth"
                                                        className="form-control"
                                                        type="date"
                                                        value={dateOfBirth}
                                                        onChange={(e) => {setDateOfBirth(e.target.value)}}
                                                        max={moment().format("YYYY-MM-DD")}
                                                        onInvalid={(e) =>
                                                            handleFormErrorMessage(e,
                                                                t("enter date no later than") +
                                                                moment().format("YYYY-MM-DD"))
                                                        }
                                                        onInput={function (e) {
                                                            e.target.setCustomValidity(t(""));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group required col-md-6">
                                                    <label htmlFor="" className="control-label">
                                                        {"Gender"}
                                                    </label>
                                                    <select
                                                        name="gender"
                                                        className="form-control float-right"
                                                        onChange={(e) => {setGender(e.target.value)}}
                                                        value={gender}
                                                        required="required">
                                                        <option name="gender" value={true}>{"FEMALE"}</option>
                                                        <option name="gender" value={false}>{"MALE"}</option>
                                                    </select>
                                                </div>
                                                <div className="form-group required col-md-6">
                                                    <label htmlFor="" className="control-label">
                                                        {t("Nationality")}
                                                    </label>
                                                    <select
                                                        name="nationality"
                                                        className="form-control float-right"
                                                        onChange={(e) => {setNationality(e.target.value)}}
                                                        value={nationality}
                                                        required="required">
                                                        {nationalities?.map((n, index) => {
                                                            return <option key={index} name="nationality">{n}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group required col-md-6">
                                                    <label htmlFor="" className="control-label">
                                                        {t("current_location")}
                                                    </label>
                                                    <select
                                                        name="country"
                                                        className="form-control float-right"
                                                        onChange={(e) => { handleCountryChanged(e.target.value); }}
                                                        value={country}
                                                        required="required">
                                                        {countries?.map((c, index) => {
                                                            return <option
                                                                        key={index}
                                                                        name="country">{c}</option>
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="form-group required col-md-6">
                                                    <label htmlFor="" className="control-label">
                                                        {"City"}
                                                    </label>
                                                    <input
                                                        name="cities"
                                                        className="form-control"
                                                        type="text"
                                                        onChange={(e) => {setCity(e.target.value)}}
                                                        value={city}
                                                        required="required"
                                                        onInput={function (e) {
                                                            e.target.setCustomValidity(t(""));
                                                        }}
                                                    />
                                                </div>
                                                
                                            </div>
                                            <div className="row">
                                                <div className="form-group required col-md-12">
                                                    <label className="control-label" htmlFor="">
                                                        {t("it_background")}
                                                    </label>
                                                    <select
                                                        name="it_background"
                                                        className="form-control float-right"
                                                        onChange={(e) => {
                                                            setITBackground(e.target.value)
                                                            setITBackgroundOthersText("");
                                                        }}
                                                        value={iTBackground}
                                                        required="required">
                                                        {iTBackgrounds.map((b, index) => {
                                                            return (
                                                                <option key={index} 
                                                                        name="it_background"
                                                                        value={b}>{b}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    {iTBackground === iTBackgrounds[iTBackgrounds.length - 1] && 
                                                        <>
                                                            <input
                                                                name="it_background"
                                                                className="form-control"
                                                                type="text"
                                                                onChange={(e) => {setITBackgroundOthersText(e.target.value)}}
                                                                value={iTBackgroundOthersText}
                                                                // disabled={}
                                                                onInput={function (e) {
                                                                    e.target.setCustomValidity(t(""));
                                                                }}
                                                                placeholder="Please specify..."
                                                            />
                                                        </>}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group required col-md-6">
                                                    <label className="control-label">{t("interest_area")}</label>
                                                    <select
                                                        name="interest_area"
                                                        className="form-control float-right"
                                                        onChange={(e) => {setInterestArea(e.target.value)}}
                                                        value={interestArea}
                                                        required="required">
                                                            {interestAreas.map((i, index) => {
                                                                return (
                                                                    <option key={index} 
                                                                            name="interest_area"
                                                                            value={i}>{i}</option>
                                                                )
                                                            })}
                                                    </select>
                                                </div>
                                                <div className="form-group required col-md-6">
                                                    <label className="control-label">{t("what_type_of_courses_do_you_want_to_register")}</label>
                                                    <select
                                                        name="what_type_of_courses_do_you_want_to_register"
                                                        className="form-control float-right"
                                                        onChange={(e) => {setTrainingType(e.target.value)}}
                                                        value={trainingType}
                                                        required="required">
                                                            {whatTypeOfCoursesDoYouWantToRegister.map((t, index) => {
                                                                return (
                                                                    <option key={index} 
                                                                            name="what_type_of_courses_do_you_want_to_register"
                                                                            value={t}>{t}</option>
                                                                )
                                                            })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group required col-md-6">
                                                    <label className="control-label">
                                                        {t("do_you_have_laptop")}
                                                    </label>
                                                    <div className="form-inline">
                                                        <div className="radio-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="haveLaptop"
                                                                id="1"
                                                                onChange={(e) => {setHaveLaptop(true)}}
                                                                checked={haveLaptop}
                                                            />
                                                            <label className="form-check-label" htmlFor="1">
                                                                {"Yes"}
                                                            </label>
                                                        </div>
                                                        <div className="radio-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="haveLaptop"
                                                                onChange={(e) => {setHaveLaptop(false)}}
                                                                checked={!haveLaptop}
                                                            />
                                                            <label className="form-check-label" htmlFor="">
                                                                {"No"}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group required col-md-6">
                                                    <label className="control-label">
                                                        {t("do_you_have_good_internet_access")}
                                                    </label>
                                                    <div className="form-inline">
                                                        <div className="radio-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="haveGoodInternetAccess"
                                                                id="1"
                                                                onChange={(e) => {setHaveGoodInternetAccess(true)}}
                                                                checked={haveGoodInternetAccess}
                                                            />
                                                            <label className="form-check-label" htmlFor="1">
                                                                {"Yes"}
                                                            </label>
                                                        </div>
                                                        <div className="radio-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="haveGoodInternetAccess"
                                                                onChange={(e) => {setHaveGoodInternetAccess(false)}}
                                                                checked={!haveGoodInternetAccess}
                                                            />
                                                            <label className="form-check-label" htmlFor="">
                                                                {"No"}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group required col-md-12">
                                                    <label className="control-label" htmlFor="">
                                                        {t("why_are_you_interested_in_attending_this_training_course")}
                                                    </label>
                                                    <select
                                                        name="why_are_you_interested"
                                                        className="form-control float-right"
                                                        onChange={(e) => {
                                                            setCourseInterestReason(e.target.value);
                                                            setCourseInterestReasonOthersText("");
                                                        }}
                                                        value={courseInterestReason}
                                                        required="required">
                                                        {whyAreYouInterested.map((w, index) => {
                                                            return (
                                                                <option key={index} 
                                                                        name="why_are_you_interested"
                                                                        value={w}>{w}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    {courseInterestReason === whyAreYouInterested[whyAreYouInterested.length - 1] && 
                                                        <>
                                                            <input
                                                                name="why_are_you_interested"
                                                                className="form-control"
                                                                type="text"
                                                                onChange={(e) => {setCourseInterestReasonOthersText(e.target.value)}}
                                                                value={courseInterestReasonOthersText}
                                                                // disabled={}
                                                                onInput={function (e) {
                                                                    e.target.setCustomValidity(t(""));
                                                                }}
                                                                placeholder="Please specify..."
                                                            />
                                                        </>}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-md-12">
                                                    <label className="control-label" htmlFor="">
                                                        {t("comment")}
                                                    </label>
                                                    <input
                                                        name="comment"
                                                        className="form-control"
                                                        type="text"
                                                        onChange={(e) => {setComment(e.target.value)}}
                                                        value={comment}
                                                        // disabled={}
                                                        onInput={function (e) {
                                                            e.target.setCustomValidity(t(""));
                                                        }}
                                                        placeholder="Please let use know if you have something to tell us"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-md-12">
                                                    <button className="btn text-white btn-theme-green btn-lg btn-block mt-15"
                                                        type="submit">
                                                        <span className="spinner-border spinner-border-sm"
                                                            role="status"
                                                            aria-hidden="true"/>
                                                        {"Submit"}
                                                    </button>
                                                </div>
                                            </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default CourseForm;