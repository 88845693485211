import React, {useEffect, useState} from "react";
import LanguageSelector from "../../i18next/LanguageSelector";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import address from "../utils/address";
import axios from "axios";

/**
 * this component returns rhe website's menu bar
 * @component
 */
function MenuBar({ magazineLink }) {
    const {t, i18n} = useTranslation();
    const [featuredOnGoingProjects, setFeaturedOnGoingProjects] = useState([]);

    async function fetchFeaturedOnGoingProjects() {
        const url = `${address()}projects/featured-ongoing`;
        const response = await axios.get(url, {
            headers: {"accept-language": `${i18n.language}`},
        });
        setFeaturedOnGoingProjects(response.data);
    }

    useEffect(() => {
        fetchFeaturedOnGoingProjects();
    }, [i18n.language]);

    return (
        <React.Fragment>
            <div className="header-nav">
                <div className="header-nav-wrapper navbar-scrolltofixed bg-silver-light">
                    <div className="container p-0">
                        <nav id="menuzord-right" className="menuzord default no-bg p-0">
                            {/* Logo */}
                            <NavLink className="pull-left flip"
                                            to="/">
                                <img
                                    src={require("../images/logo.png")}
                                    className="menuzord-logo"
                                    style={{
                                        // height: "40px",
                                        width: "220px",
                                        [i18n.dir() === "ltr" ? "left" : "right"]: 0,
                                        top: 0
                                    }}
                                    alt="Sadagaat Logo" />
                            </NavLink>
                            {/* Menu List */}
                            <ul className="menuzord-menu">
                                <li className="nav-link">
                                    <NavLink
                                        activeclassName="active"
                                        exact
                                        to="/">
                                        {t("Home")}
                                    </NavLink>
                                </li>
                                <li className="nav-link">
                                    <a>
                                        {t("About Us")} <span className="indicator"></span>
                                    </a>
                                    <ul className="dropdown">
                                        <li>
                                            <NavLink to="/about">
                                                {t("About Us")}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/contact"
                                            >
                                                {t("Contact")}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-link">
                                    <a>
                                        {t("Sectors")}
                                        <span className="indicator"></span>{" "}
                                    </a>
                                    <ul className="dropdown">
                                        <li>
                                            <NavLink to="/wash">
                                                {t("Water Sector")}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/education">
                                                {t("Education Sector")}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/health">
                                                {t("Health Sector")}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/feeding">
                                                {t("Feeding Sector")}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-link">
                                    <a>
                                        {t("Projects")}
                                        <span className="indicator"></span>{" "}
                                    </a>
                                    <ul className="dropdown">
                                        <li>
                                            <NavLink to="/projects">
                                                {t("Ongoing")}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/planned-projects">
                                                {t("Planned")}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-link">
                                    <a>
                                        {t("featured_ongoing_projects")} <span className="indicator"></span>
                                    </a>
                                    <ul className="dropdown">
                                        {featuredOnGoingProjects.map((project, index) => {
                                            return (
                                                <li key={index}>
                                                    <NavLink
                                                             to={`/single-projects/${project.id}`}>
                                                        {project.name}
                                                    </NavLink>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </li>
                                <li className="nav-link">
                                    <a>
                                        {t("Media")} <span className="indicator"></span>
                                    </a>
                                    <ul className="dropdown">
                                        <li>
                                            <NavLink to="/gallery">
                                                {t("Photo")}{" "}{t("Gallery")}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink  to="/news">
                                                {t("News")}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                     to={{ pathname: magazineLink }}
                                                     target="_blank">
                                                {t("checkout_monthly_magazine")}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-link">
                                    <a>
                                        {t("join_us")} <span className="indicator"></span>
                                    </a>
                                    <ul className="dropdown">
                                        <li>
                                            <NavLink to="/projects">
                                                {t("donate_to_project")}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <a>
                                                {t("Become a Volunteer")} <span className="indicator"></span>
                                            </a>
                                            <ul className="dropdown">
                                                <li>
                                                    <NavLink to="/volunteers">
                                                        {t("About") + " " + t("Volunteers")}
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink
                                                        activeclassName="active"
                                                        to="/volunteerForm">
                                                        {t("Volunteers Form")}
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/Volunteer-Programs">
                                                        {t("Volunteers Programs")}
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <NavLink to="/calendar">
                                                {t("attend_an_event")}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/careers">
                                                {t("Careers")}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-link">
                                    <NavLink
                                        activeclassName="active"
                                        exact
                                        to="/sudanese-learning-hub">
                                        {t("sudanese_learning_hub")}
                                    </NavLink>
                                </li>
                                <li>
                                    {" "}
                                    <LanguageSelector/>{" "}
                                </li>
                                <li>
                                    <button
                                        className="btn btn-color-green pl-20 pr-20 font-weight-700"
                                        onClick={() => { window.location.href = '/donate' }}
                                        style={{width: "auto", borderRadius: '5'}}>
                                        <i className={`fa fa-heart text-white ${i18n.dir() === "ltr" ? "mr-10" : "ml-10"}`}/>
                                        {t("Donate")}
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default MenuBar;
