import React, { useRef, useState, Component, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ModalVedioComponent from "./video";
import address from "../utils/address";
import i18n from "i18next";


const Video = () => {
  const { t } = useTranslation();
  const [link,setLink]= useState({})


  async function fetchTable() {
    const fetcher = await window.fetch(`${address()}video-config`,
    {headers: {'accept-language': `${i18n.language}`}
   });
   const response = await fetcher.json();
   setLink(response);
   console.log("the fetched link text .*******",link.text)
 
 
 }
 useEffect(()=>{
fetchTable();
 },[i18n.language])
  return (
    <React.Fragment>
        <section className="bg-widget-mask divider parallax layer-overlay overlay-dark-1"
                 data-parallax-ratio="0.1">
            <img className="absolute"
                 src="/images/curve.svg"
                 style={{
                     opacity: '0.7',
                     width: '100%',
                     height: '100%',
                     objectFit: 'cover'
                 }}
                 alt="mask"/>
            <div className="display-table">
                <div className="display-table-cell">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <ModalVedioComponent />
                                {<p className="font-20 text-white mt-20">
                                    {link.text ?? <span>{t("video text")}</span>}
                                </p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
  );
};

export default Video;
