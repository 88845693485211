import React, {useEffect, useState} from "react";
import {Switch, Route, Link} from "react-router-dom";
import TopBar from "./components/top_bar";
import MenuBar from "./components/menu_bar";
import Footer from "./components/footer";
import Become from "./components/become_a_volunteer/index";
import Contact from "./components/contact";
import About from "./components/about/about.js";
import Projects_ from "./components/projects/projects";
import SearchResults from "./components/ProjectsFilter/SearchResults";
import Home from "./components/home";
import Calendar from "./components/events/eventsCalender";
import Feeding from "./components/base_hubs/Feeding";
import Education from "./components/base_hubs/education";
import Health from "./components/base_hubs/health";
import Water from "./components/base_hubs/water";
import News from "./components/news/index";
import VolunteerForm from "./components/vlounteers/VolunteerForm";
import Donate from "./components/Donation/donate";
import SingleProject2 from "./components/projects/single-withSlider";
import SingleSubhub from "./components/sub_hubs/single-subhub";
import SingleEvent from "./components/events/single-event";
import PlannedProjects from "./components/projects/plannedProjects";
import FinishedProjects from "./components/projects/finished-project";
import singleNews from "./components/news/single-news";
import Volunteers from "./components/vlounteers/volunteersPage";
import Programs from "./components/vlounteers/programs";
import SingleVolunteersProgram from "./components/vlounteers/single-program";
import AllCareers from "./components/careers/all-careers";
import Vacancy from "./components/careers/details";
import Gallery from "./components/More_gallery/gallery";
import WaterDetails from "./components/base_hubs/details/index";
import SudaneseLearningHub from "./components/sudanese_learning_hub";
import address from "./components/utils/address";
import axios from "axios";
import {useTranslation} from "react-i18next";
import CourseForm from "./components/course_form";

const AppRoutes = () => {
    const {t, i18n} = useTranslation();
    const [magazineLinks, setMagazineLinks] = useState({
        allMagazineLink: "", currentMagazineLink: ""
    });
    const dir = i18n.dir();
    const isEnglish = dir === "ltr";

    async function fetchMagazine() {
        try {
            const url = `${address()}magazine`;
            const res = await axios.get(url);
            setMagazineLinks(res.data);
        } catch (ex) {
            console.log("Unable to fetch magazine links!");
        }
    }

    useEffect(() => {
        fetchMagazine();
    }, [i18n.language]);

    return (
        <React.Fragment>
            <Switch>
                <div className="main-content">
                    <Link className="position-fixed btn btn-color-green z-index-9999 font-20"
                          style={{
                              top: '30%',
                              left: isEnglish ? '-44px' : '-30px',
                              transform: 'rotate(-90deg)',
                              borderRadius: '0px 0px 20px 20px'
                          }}
                          to='/donate'>
                        {t("Donate")}
                        <i className={`fa fa-heart text-white ${isEnglish ? "ml-5" : "mr-5"}`}/>
                    </Link>
                    <TopBar/>
                    <MenuBar magazineLink={magazineLinks.currentMagazineLink}/>

                    <Route exact path="/" component={Home}/>
                    <Route path="/about" component={About}/>
                    <Route exact path="/projects" component={Projects_}/>
                    <Route exact path="/wash" component={Water}/>
                    <Route exact path="/feeding" component={Feeding}/>
                    <Route exact path="/health" component={Health}/>
                    <Route exact path="/education" component={Education}/>
                    <Route exact path="/news" component={News}/>
                    <Route path="/contact" component={Contact}/>
                    <Route exact path="/donate/" component={Donate}/>
                    <Route exact path="/calendar">
                        <Calendar/>
                    </Route>
                    <Route exact path="/wash/details" component={WaterDetails}/>

                    <Route exact path="/Search-Results" component={SearchResults}></Route>
                    <Route exact path="/volunteers" component={Volunteers}/>
                    <Route exact path="/complete-projects" component={FinishedProjects}/>
                    <Route exact path="/planned-projects" component={PlannedProjects}/>

                    <Route exact path="/event/:event_id" component={SingleEvent}/>
                    <Route exact path="/news/:news_id" component={singleNews}/>
                    <Route exact path="/volunteerForm" component={VolunteerForm}/>
                    <Route exact path="/Volunteer-Programs" component={Programs}/>
                    <Route
                        exact
                        path="/Volunteer-Programs/:id"
                        component={SingleVolunteersProgram}
                    />
                    <Route exact path="/careers" component={AllCareers}/>
                    <Route exact path="/gallery" component={Gallery}/>
                    <Route exact path="/vacancy/:id" component={Vacancy}/>
                    <Route
                        exact
                        path="/single-projects/:project_id"
                        component={SingleProject2}
                    />
                    <Route
                        exact
                        path="/single-subhub/:subhub_id"
                        component={SingleSubhub}
                    />
                    <Route 
                        exact 
                        path="/sudanese-learning-hub" 
                        component={SudaneseLearningHub}
                    />
                    <Route 
                        exact 
                        path="/course-form" 
                        component={CourseForm}
                    />
                    <Become/>
                    <Footer magazineLink={magazineLinks.allMagazineLink}/>
                </div>
            </Switch>
        </React.Fragment>
    );
};

export default AppRoutes;
