import React from "react";
import { Link, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
/**
 * This component showing becom a volunteer section this section hide from volunteerForm page
 * @component
 * @param {object} props  PathName  of the page
 * @see http://sadagaat.com
 */
function Become(props) {
  const { t } = useTranslation();
  // Hide in the volunteer form page
  if (props.history.location.pathname === "/volunteerForm")
    return <></>

  return (
      <React.Fragment>
        <section className="divider parallax layer-overlay overlay-dark-1 bg-widget-mask bg-color-yellow"
                 data-parallax-ratio="0.7">
          <div className="container pt-0 pb-0">
            <div className="row">
              <div className="col-md-12">
                <div className="call-to-action pt-30 pb-30">
                  <div className="col-sm-9 col-xs-12 xs-text-center">
                    <h3 className="text-theme-colored">
                      {t("join us now as a volunteer")}
                    </h3>
                  </div>
                  <div className="col-sm-3 col-xs-12 text-right xs-text-center">
                    {/* Link to Volunteer Form */}
                    <Link to="/volunteerForm">
                      <button className="btn btn-transparent bg-hover-theme-colored btn-border border-theme-colored btn-circled btn-lg mt-15 text-theme-colored">
                        {t("Become a Volunteer")}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
  );
}
export default withRouter(Become);
