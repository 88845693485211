import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import address from "../utils/address";
import parse from "html-react-parser";

function Footer({ magazineLink }) {
    const {t, i18n} = useTranslation();
    const [footer, setFooter] = useState([]);
    const [contact, setContact] = useState({})
    const [location, setLocation] = useState({})
    const dir = i18n.dir();
    const isEnglish = dir === "ltr";

    /**
     * this component display footer section appear in each page
     * @component
     */
    async function fetchTable() {
        const fetcher = await window.fetch(`${address()}copy-right`,
            {
                headers: {'accept-language': `${i18n.language}`}
            });
        const response = await fetcher.json();
        setFooter(response);
        console.log("the fetched Footer .*****************.*****.**************", response.text)
    }

    async function fetchContact() {
        const fetcher = await window.fetch(`${address()}contact-info/UK`,
            {
                headers: {'accept-language': `${i18n.language}`}
            });
        const response = await fetcher.json();
        setContact(response);
        setLocation(contact.location)
    }

    useEffect(() => {
        fetchTable();
        fetchContact();

    }, [i18n.language])

    return (
        <>
            <footer
                id="footer"
                className="footer"
                data-bg-img="images/footer-mask.svg"
                data-bg-color="#25272e">
                <div className="container pt-70 pb-40">
                    <div className="row border-bottom-black">
                        <div className="col-sm-6 col-md-3">
                            <div className="widget dark">
                                <h2 className="text-white pb-0 m-0">{t("SADAGAAT")}</h2>
                                <h4 className="text-white text-decoration-inline">
                                    {t("hashtag")}
                                </h4>
                                <ul className="list-inline styled-icons icon-dark icon-theme-colored icon-circled icon-sm p-0 m-0">
                                    <li>
                                        <a href={(contact.length !== 0 && contact.facebook != null) ?
                                                  contact.facebook : "http://www.facebook.com/groups/sadagaat"}
                                            target="blank">
                                            <i className="fa fa-facebook"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={(contact.length !== 0 && contact.twitter != null) ?
                                                  contact.twitter : "http://twitter.com/sadagaat"}
                                           target="blank">
                                            <i className="fa fa-twitter"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={(contact.length !== 0 && contact.linkedin != null) ?
                                            contact.linkedin :
                                            "https://www.linkedin.com/pub/sadagaat-sudan/54/431/5b7"}
                                           target="blank">
                                            <i className="fa fa-linkedin"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={(contact.length !== 0 && contact.youtube != null) ?
                                            contact.youtube :
                                            "https://www.youtube.com/channel/UCRSvaVpv6Hw6eUnI6OsutSQ"}
                                           target="blank">
                                            <i className="fa fa-youtube-play"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={(contact.length !== 0 && contact.instagram != null) ?
                                            contact.instagram : "https://www.instagram.com/sadagaat_organization/"}
                                            target="blank">
                                            <i className="fa fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={(contact.length !== 0 && contact.soundcloud != null) ?
                                            contact.soundcloud : "https://soundcloud.com/sadagaat"}
                                           target="blank">
                                            <i className="fa fa-soundcloud"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={(contact.length !== 0 && contact.soundcloud != null) ?
                                            contact.tiktok : "https://www.tiktok.com/@sadagaatsudan"}
                                           target="blank">
                                            <i className="fa">
                                                <svg style={{ width: 12, height: 12 }}
                                                     fill="currentColor"
                                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path fillRule="evenodd" d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/>
                                                </svg>
                                            </i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="widget dark">
                                <h5 className="widget-title line-bottom-white">{t("Sectors")}</h5>
                                <ul className="list angle-double-right list-border p-0 m-0">
                                    <li className="m-0">
                                        <Link to="/wash">{t("Water Sector")}</Link>
                                    </li>
                                    <li className="m-0">
                                        <Link to="/health">{t("Health Sector")}</Link>
                                    </li>
                                    <li className="m-0">
                                        <Link to="/feeding">{t("Feeding Sector")}</Link>
                                    </li>
                                    <li className="m-0">
                                        <Link to="/education">{t("Education Sector")}</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="widget dark">
                                <h5 className="widget-title line-bottom-white">
                                    {t("Quick Links")}
                                </h5>
                                <ul className="list angle-double-right list-border p-0 m-0">
                                    <li className="m-0">
                                        <Link to="/about">{t("About Us")}</Link>
                                    </li>
                                    <li className="m-0">
                                        <Link to="/projects">{t("Projects")}</Link>
                                    </li>
                                    <li className="m-0">
                                        <Link to="/volunteerForm">{t("Become a Volunteer")}</Link>
                                    </li>
                                    <li className="m-0">
                                        <Link to={{ pathname: magazineLink }} target="_blank">{t("our_magazine")}</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="widget dark">
                                <h5 className="widget-title line-bottom-white">
                                    {t("Contact Us")}
                                </h5>
                                <ul className="list angle-double-right list-border p-0 m-0">
                                    <li className="m-0">
                                        <p>
                                            <i className={`fa fa-phone ${isEnglish ? "mr-10" : "ml-10"}`}/>
                                            {`+${contact.phone ?? "447884060063"}`}
                                        </p>
                                    </li>
                                    <li className="m-0">
                                        <p>
                                            <i className={`fa fa-envelope-o ${isEnglish ? "mr-10" : "ml-10"}`}/>
                                            {contact.email ?? "info@sadagaat-uk.org"}
                                        </p>
                                    </li>
                                    <li className="m-0">
                                        <p>
                                            <i className={`fa fa-globe ${isEnglish ? "mr-10" : "ml-10"}`}/>
                                            {contact.website ?? "http://sadagaat-uk.org"}
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom bg-theme-colored">
                    <div className="container pt-15 pb-10">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="font-12 text-white text-center m-0">
                                    {footer.length !== 0 ?
                                        parse(`${footer.text}`) :
                                        t("All Rights Reserved © Sadagaat Community of Charity - 2020")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
            <Link className="scrollToTop" to={'/'}>
                <i className="fa fa-angle-up"/>
            </Link>
        </>
    );
}

export default Footer;
